import { WithImageOverlay } from 'components/ToolkitV2/CardV2/enhancements/WithImageOverlay/WithImageOverlay';
import { Image } from 'components/ToolkitV2/CardV2/components/Image/Image';
import { ORIENTATION } from 'components/ToolkitV2/CardV2/Card.typed';
import { Card } from 'components/ToolkitV2/CardV2/Card';
import { LifestyleLinks } from 'types';
import { CDN_STATIC_ASSETS } from 'utils';
import * as Styled from 'components/HomePage/components/MotorSearch/LifeStyleCarousel/LifeStyleCarousel.styled';
import { thousandSeparated } from 'helpers/formatting';
import { Carousel } from 'features/carousel/Carousel';
import { WhiteSection } from 'components/HomePage/styles/HomePage.styled';

const LifeStyleCarousel = ({
  lifestyleLinks,
}: {
  lifestyleLinks: LifestyleLinks[];
}) => {
  return (
    <WhiteSection>
      <Styled.Wrapper>
        <Carousel
          title="Browse cars by lifestyle"
          scrollDistance={212}
          accessibilityLabel="lifestyle carousel"
          data-testid="lifestyle-carousel"
          id="lifestyle_carousel"
        >
          {lifestyleLinks.map((item) => {
            return (
              <Styled.WrapperLifeStyleItem
                href={item.url}
                key={item.name}
                dataTracking={`lifestyle-${item.name}`}
              >
                <Card
                  header={<Styled.Title>{item.displayName}</Styled.Title>}
                  body={
                    <Styled.Counter>
                      {thousandSeparated(item.count)}
                    </Styled.Counter>
                  }
                  image={
                    <WithImageOverlay>
                      <Image
                        orientationSmall={ORIENTATION.HORIZONTAL}
                        src={`${CDN_STATIC_ASSETS}/images/illustrations/${item.name}.webp`}
                        imgSrcWebp={`${CDN_STATIC_ASSETS}/images/illustrations/${item.name}.webp`}
                        alt={item.displayName}
                        widthHorizontalLarge={180}
                        widthHorizontalMedium={180}
                        widthHorizontalSmall={180}
                      />
                    </WithImageOverlay>
                  }
                  orientationSmall={ORIENTATION.VERTICAL}
                  backgroundColor={'OFFWHITE'}
                  paddingSmall={'S4'}
                />
              </Styled.WrapperLifeStyleItem>
            );
          })}
        </Carousel>
      </Styled.Wrapper>
    </WhiteSection>
  );
};

export { LifeStyleCarousel };
