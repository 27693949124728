import { useRef, useState } from 'react';

import type { useCarouselArgs } from 'features/carousel/Carousel.hook.typed';

// Inspired by: https://jcxmpbxll.medium.com/creating-a-carousel-with-horizontal-scrolling-using-react-19efbf67b47d
const useCarousel = (props: useCarouselArgs) => {
  const { scrollDistance } = props;

  const [positionStatuses, setPositionStatuses] = useState({
    isAtStartPosition: true,
    isAtEndPosition: false,
  });

  const carouselRef = useRef<HTMLDivElement>(null);

  /*
   * We use a combination of Element properties to determine when and how far to scroll. This includes the scrollLeft, clientWidth, and scrollWidth properties.
   *
   * "The scrollLeft property gets or sets the number of pixels by which an element's content is scrolled from its left edge": https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollLeft
   *
   * "The scrollWidth read-only property is a measurement of the width of an element's content, including content not visible on the screen due to overflow": https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollWidth
   *
   * "The clientWidth property is zero for inline elements and elements with no CSS; otherwise, it's the inner width of an element in pixels.
   * It includes padding but excludes borders, margins, and vertical scrollbars (if present)": https://developer.mozilla.org/en-US/docs/Web/API/Element/clientWidth
   *
   * There are conditions in scrollLeft and scrollRight to ensure we do not try to scroll with negative values, or values that exceed the value of scrollWidth.
   */

  const scrollLeft = () => {
    if (
      typeof carouselRef.current?.scrollLeft === 'number' &&
      carouselRef.current?.scrollLeft - scrollDistance > 0
    ) {
      carouselRef.current?.scrollTo(
        carouselRef.current?.scrollLeft - scrollDistance,
        0,
      );
    } else {
      carouselRef.current?.scrollTo(0, 0);
    }
  };

  const scrollRight = () => {
    if (
      typeof carouselRef.current?.scrollLeft === 'number' &&
      carouselRef.current?.scrollWidth - carouselRef.current?.scrollLeft >=
        scrollDistance
    ) {
      carouselRef.current?.scrollTo(
        carouselRef.current?.scrollLeft + scrollDistance,
        0,
      );
    } else {
      carouselRef.current?.scrollTo(carouselRef.current?.scrollWidth, 0);
    }
  };

  const onScroll = () => {
    if (typeof carouselRef.current?.scrollLeft === 'number') {
      /*
       * By checking how far the user has scrolled with scrollLeft, we can determine if the carousel is in its starting position.
       *
       * By adding the visible element (clientWidth) to the distance scrolled (scrollLeft), we can determine if the user has scrolled to the end of the element (scrollWidth)
       *
       * On scroll, we trigger a rerender so that the correct visual state is displayed i.e. whether the scroll buttons are disabled or not.
       */
      setPositionStatuses({
        isAtStartPosition: carouselRef.current?.scrollLeft === 0,
        isAtEndPosition:
          (carouselRef.current?.scrollLeft ?? 0) +
            (carouselRef.current?.clientWidth ?? 0) ===
          (carouselRef.current?.scrollWidth ?? 0),
      });
    }
  };

  return {
    scrollLeft,
    scrollRight,
    onScroll,
    carouselRef,
    isAtStartPosition: positionStatuses.isAtStartPosition,
    isAtEndPosition: positionStatuses.isAtEndPosition,
  };
};

export { useCarousel };
