import { AdSlot } from '@dsch/react-dfp';
import getConfig from 'next/config';

import { DFPUnitSizes, DFPScreenSizes } from 'components/Toolkit/DFP/DFPSizes';
import type { UnitProps } from 'components/Toolkit/DFP';

const {
  publicRuntimeConfig: { DFP_NETWORK_ID },
} = getConfig();

/**
 * We disable DFP Units in cases such as our integration tests.
 * This is at an environment level at the moment. This simply removes the unit
 * from the DOM so all scripts should still run. This will allow us to catch
 * errors.
 */
const dfpDisabled = !DFP_NETWORK_ID || DFP_NETWORK_ID.length <= 0;

const {
  LEADERBOARD,
  LEADERBOARD_XL,
  LEADERBOARD_TALL,
  MOBILE_LEADERBOARD,
  MPU,
  MPU_300,
  SKYSCRAPER_WIDE,
  SKYSCRAPER,
  SKYSCRAPER_SLIM,
  BILLBOARD,
  LINE_TEXT_UNIT,
  FLUID,
  ONE_BY_ONE,
  BLANK,
  CUSTOM_LIFESTYLES,
} = DFPUnitSizes;

const {
  VIEW_MOBILE,
  VIEW_TABLET_PLUS,
  VIEW_DESKTOP_PLUS,
  VIEW_DESKTOP_LARGEST,
  VIEW_AD_DETAIL_SIDEBAR_VISIBLE,
  VIEW_SEARCH_PAGE_SIDEBAR_VISIBLE,
} = DFPScreenSizes;

interface DFPConfig {
  [key: string]: UnitProps;
}

export const AD_DETAILS_DFP_CONFIG: DFPConfig = {
  dd_detail_top: {
    shouldRefresh: () => false,
    slotId: 'dd_detail_top',
    adUnit: 'donedeal/dd_detail_top',
    sizeMapping: [
      {
        viewport: VIEW_DESKTOP_PLUS,
        sizes: [LEADERBOARD, LEADERBOARD_XL],
      },
      {
        viewport: VIEW_TABLET_PLUS,
        sizes: [LEADERBOARD],
      },
      {
        viewport: VIEW_MOBILE,
        sizes: [MOBILE_LEADERBOARD],
      },
    ],
    sizes: [LEADERBOARD, LEADERBOARD_XL, MOBILE_LEADERBOARD],
    dfpDisabled,
    AdSlot,
  },
  dd_detail_calculator: {
    shouldRefresh: () => false,
    slotId: 'dd_detail_calculator',
    adUnit: 'donedeal/dd_detail_calculator',
    sizeMapping: [
      {
        viewport: VIEW_MOBILE,
        sizes: ['fluid'],
      },
    ],
    sizes: ['fluid'],
    dfpDisabled,
    AdSlot,
  },
  dd_detail_textlink: {
    shouldRefresh: () => false,
    slotId: 'dd_detail_textlink',
    adUnit: 'donedeal/dd_detail_textlink',
    sizeMapping: [
      {
        viewport: VIEW_MOBILE,
        sizes: [LINE_TEXT_UNIT],
      },
    ],
    sizes: [LINE_TEXT_UNIT],
    dfpDisabled,
    AdSlot,
  },
  dd_detail_textlink_alt: {
    shouldRefresh: () => false,
    slotId: 'dd_detail_textlink_alt',
    adUnit: 'donedeal/dd_detail_textlink_alt',
    sizeMapping: [
      {
        viewport: VIEW_MOBILE,
        sizes: [LINE_TEXT_UNIT],
      },
    ],
    sizes: [LINE_TEXT_UNIT],
    dfpDisabled,
    AdSlot,
  },
  dd_detail_promo_panel: {
    shouldRefresh: () => false,
    slotId: 'dd_detail_promo_panel',
    adUnit: 'donedeal/dd_detail_promo_panel',
    sizeMapping: [
      {
        viewport: VIEW_MOBILE,
        sizes: ['fluid'],
      },
    ],
    sizes: ['fluid'],
    dfpDisabled,
    AdSlot,
  },
  dd_detail_mid: {
    shouldRefresh: () => false,
    slotId: 'dd_detail_mid',
    adUnit: 'donedeal/dd_detail_mid',
    sizeMapping: [
      {
        viewport: VIEW_AD_DETAIL_SIDEBAR_VISIBLE,
        sizes: [LEADERBOARD],
      },
      {
        viewport: [1024, 0],
        sizes: [],
      },
      {
        viewport: [760, 0],
        sizes: [LEADERBOARD],
      },
      {
        viewport: [760, 0],
        sizes: [LEADERBOARD],
      },
      {
        viewport: VIEW_MOBILE,
        sizes: [MPU, MOBILE_LEADERBOARD, MPU_300],
      },
    ],
    sizes: [MPU, MOBILE_LEADERBOARD, MPU_300, LEADERBOARD],
    dfpDisabled,
    AdSlot,
  },
  dd_detail_side: {
    shouldRefresh: () => false,
    slotId: 'dd_detail_side',
    adUnit: 'donedeal/dd_detail_side',
    sizeMapping: [
      {
        viewport: VIEW_AD_DETAIL_SIDEBAR_VISIBLE,
        sizes: [SKYSCRAPER_WIDE, 'fluid'],
      },
    ],
    sizes: [SKYSCRAPER_WIDE, 'fluid'],
    dfpDisabled,
    AdSlot,
  },
  dd_detail_photobrowser: {
    shouldRefresh: () => true,
    slotId: 'dd_detail_photobrowser',
    adUnit: 'donedeal/dd_detail_photobrowser',
    sizeMapping: [
      {
        viewport: [1024, 0],
        sizes: [SKYSCRAPER_WIDE, MPU, SKYSCRAPER, SKYSCRAPER_SLIM, MPU_300],
      },
      {
        viewport: VIEW_MOBILE,
        sizes: [MOBILE_LEADERBOARD],
      },
    ],
    sizes: [
      MPU,
      SKYSCRAPER_WIDE,
      SKYSCRAPER,
      SKYSCRAPER_SLIM,
      MPU_300,
      MOBILE_LEADERBOARD,
    ],
    dfpDisabled,
    AdSlot,
  },
};

const baseHomepageDFPConfig = {
  shouldRefresh: () => true,
  sizeMapping: [
    {
      viewport: VIEW_DESKTOP_PLUS,
      sizes: [CUSTOM_LIFESTYLES, BILLBOARD, LEADERBOARD_XL, LEADERBOARD_TALL],
    },
    {
      viewport: VIEW_TABLET_PLUS,
      sizes: [LEADERBOARD, LEADERBOARD_TALL],
    },
    {
      viewport: VIEW_MOBILE,
      sizes: [MOBILE_LEADERBOARD, MPU],
    },
  ],
  sizes: [MOBILE_LEADERBOARD, MPU, CUSTOM_LIFESTYLES, BILLBOARD],
  dfpDisabled,
  AdSlot,
};

export const HOME_PAGE_DFP_CONFIG: DFPConfig = {
  dd_homepage_top_motor: {
    ...baseHomepageDFPConfig,
    slotId: 'dd_homepage_top_motor',
    adUnit: 'donedeal/dd_homepage_top_motor',
  },
  dd_homepage_top_marketplace: {
    ...baseHomepageDFPConfig,
    slotId: 'dd_homepage_top_marketplace',
    adUnit: 'donedeal/dd_homepage_top_marketplace',
  },
  dd_homepage_top_farming: {
    ...baseHomepageDFPConfig,
    slotId: 'dd_homepage_top_farming',
    adUnit: 'donedeal/dd_homepage_top_farming',
  },
};

export const SEARCH_PAGE_DFP_CONFIG: DFPConfig = {
  dd_search_top: {
    shouldRefresh: () => true,
    slotId: 'dd_search_top',
    adUnit: 'donedeal/dd_search_top',
    sizeMapping: [
      {
        viewport: VIEW_DESKTOP_PLUS,
        sizes: [BILLBOARD, LEADERBOARD_XL, LEADERBOARD],
      },
      {
        viewport: VIEW_TABLET_PLUS,
        sizes: [LEADERBOARD],
      },
    ],
    sizes: [BILLBOARD, LEADERBOARD, LEADERBOARD_XL],
    dfpDisabled,
    AdSlot,
  },
  dd_search_left_skyscraper: {
    shouldRefresh: () => true,
    slotId: 'dd_search_left_skyscraper',
    adUnit: 'donedeal/dd_search_left_skyscraper',
    sizeMapping: [
      {
        viewport: VIEW_SEARCH_PAGE_SIDEBAR_VISIBLE,
        sizes: [SKYSCRAPER, SKYSCRAPER_SLIM, SKYSCRAPER_WIDE],
      },
    ],
    sizes: [SKYSCRAPER, SKYSCRAPER_SLIM, SKYSCRAPER_WIDE],
    dfpDisabled,
    AdSlot,
  },
  dd_search_5: {
    shouldRefresh: () => true,
    slotId: 'dd_search_5',
    adUnit: 'donedeal/dd_search_5',
    sizeMapping: [
      {
        viewport: [1073, 0],
        sizes: [FLUID, LEADERBOARD],
      },
      {
        viewport: [891, 0],
        sizes: [FLUID],
      },
      {
        viewport: VIEW_TABLET_PLUS,
        sizes: [FLUID, LEADERBOARD],
      },
      {
        viewport: [428, 0],
        sizes: [FLUID],
      },
      {
        viewport: [320, 640],
        sizes: [FLUID, MPU_300, MOBILE_LEADERBOARD],
      },
      {
        viewport: VIEW_MOBILE,
        sizes: [FLUID, MPU_300, MOBILE_LEADERBOARD],
      },
    ],
    sizes: [FLUID, MOBILE_LEADERBOARD, LEADERBOARD],
    dfpDisabled,
    AdSlot,
  },
  dd_search_native: {
    shouldRefresh: () => true,
    slotId: 'dd_search_native',
    adUnit: 'donedeal/dd_search_native',
    sizeMapping: [
      {
        viewport: VIEW_MOBILE,
        sizes: [FLUID],
      },
    ],
    sizes: [FLUID],
    dfpDisabled,
    AdSlot,
  },
  dd_search_10_dfp: {
    shouldRefresh: () => true,
    slotId: 'dd_search_10_dfp', // appended dfp as there is an AdSense unit with same id
    adUnit: 'donedeal/dd_search_10',
    sizeMapping: [
      {
        viewport: [1073, 0],
        sizes: [LEADERBOARD, ONE_BY_ONE],
      },
      {
        viewport: [891, 0],
        sizes: [ONE_BY_ONE],
      },
      {
        viewport: VIEW_TABLET_PLUS,
        sizes: [FLUID, LEADERBOARD],
      },
      {
        viewport: [428, 0],
        sizes: [ONE_BY_ONE],
      },
      {
        viewport: VIEW_MOBILE,
        sizes: [MPU_300, MOBILE_LEADERBOARD, ONE_BY_ONE],
      },
    ],
    sizes: [MOBILE_LEADERBOARD],
    dfpDisabled,
    AdSlot,
  },
  dd_search_14: {
    shouldRefresh: () => true,
    slotId: 'dd_search_14',
    adUnit: 'donedeal/dd_search_14',
    sizeMapping: [
      {
        viewport: [1073, 0],
        sizes: [FLUID, LEADERBOARD],
      },
      {
        viewport: [891, 0],
        sizes: [FLUID],
      },
      {
        viewport: VIEW_TABLET_PLUS,
        sizes: [FLUID, LEADERBOARD],
      },
      {
        viewport: [428, 0],
        sizes: [FLUID],
      },
      {
        viewport: [320, 640],
        sizes: [FLUID, MPU_300, MOBILE_LEADERBOARD],
      },
      {
        viewport: VIEW_MOBILE,
        sizes: [FLUID, MPU_300, MOBILE_LEADERBOARD],
      },
    ],
    sizes: [FLUID, MOBILE_LEADERBOARD, LEADERBOARD],
    dfpDisabled,
    AdSlot,
  },
  dd_search_22: {
    shouldRefresh: () => true,
    slotId: 'dd_search_22',
    adUnit: 'donedeal/dd_search_22',
    sizeMapping: [
      {
        viewport: [1073, 0],
        sizes: [FLUID, LEADERBOARD],
      },
      {
        viewport: [891, 0],
        sizes: [FLUID],
      },
      {
        viewport: VIEW_TABLET_PLUS,
        sizes: [FLUID, LEADERBOARD],
      },
      {
        viewport: [428, 0],
        sizes: [FLUID],
      },
      {
        viewport: [320, 640],
        sizes: [FLUID, MPU_300, MOBILE_LEADERBOARD],
      },
      {
        viewport: VIEW_MOBILE,
        sizes: [FLUID, MPU_300, MOBILE_LEADERBOARD],
      },
    ],
    sizes: [FLUID, MOBILE_LEADERBOARD, LEADERBOARD],
    dfpDisabled,
    AdSlot,
  },
  dd_search_bottom: {
    shouldRefresh: () => true,
    slotId: 'dd_search_bottom',
    adUnit: 'donedeal/dd_search_bottom',
    sizeMapping: [
      {
        viewport: [1045, 0],
        sizes: [LEADERBOARD],
      },
      {
        viewport: [876, 0],
        sizes: [BLANK],
      },
      {
        viewport: VIEW_TABLET_PLUS,
        sizes: [LEADERBOARD],
      },
      {
        viewport: VIEW_MOBILE,
        sizes: [MOBILE_LEADERBOARD],
      },
    ],
    sizes: [LEADERBOARD],
    dfpDisabled,
    AdSlot,
  },
};

export const MY_DONEDEAL_DFP_CONFIG: DFPConfig = {
  dd_my_garage: {
    shouldRefresh: () => false,
    slotId: 'dd_my_garage',
    adUnit: 'donedeal/dd_my_garage',
    sizeMapping: [
      {
        viewport: VIEW_DESKTOP_LARGEST,
        sizes: [LEADERBOARD],
      },
      {
        viewport: VIEW_TABLET_PLUS,
        sizes: [MOBILE_LEADERBOARD],
      },
      {
        viewport: VIEW_MOBILE,
        sizes: [MPU],
      },
    ],
    sizes: [MPU, MOBILE_LEADERBOARD, LEADERBOARD],
    dfpDisabled,
    AdSlot,
  },
};
