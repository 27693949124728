import type { GetServerSideProps } from 'next';
import { getServerSession } from 'next-auth';
import axios, { type AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import Head from 'next/head';
import getConfig from 'next/config';
import { NextSeo } from 'next-seo';

import { VerificationProvider } from 'contexts/VerificationContext';
import { DefaultLayout } from 'components/Layouts';
import { adViewApi } from 'api/adViewApi';

import type { HomePageResponse } from 'api/types/adviewApiTypes';
import { getServerCookie } from 'utils/CookieUtils';
import { HomePage } from 'components/HomePage/HomePage';
import { pushToDataLayer } from 'services/gtmService';
import { Notifications, User, UserCookieInfo } from 'types';
import { logServerError } from 'helpers/logger';
import { PAGE } from 'helpers/pages';
import { VERTICAL } from 'helpers/verticals';
import { generateRecaptchaToken } from 'helpers/reCaptcha';
import { getH1 } from 'components/HomePage/hooks/useHomePage';
import { SiteFocus } from 'components/HomePage/types';
import { userLogout } from 'helpers/Logout';
import { useRouter } from 'next/router';
import { useUserContext } from 'contexts/UserContext';
import { authOptions } from 'pages/api/auth/[...nextauth]';
import { userRepository } from 'repositories/User/UserRepository';
import { isOk } from 'domains/Result';
import { formatAuthorizationHeader } from 'helpers/auth';
import { HomePageProvider } from 'contexts/HomePageContext';
import { searchPageApi } from 'api/searchPageApi';
import type { ISearchPageFilter } from 'api/types/searchPageApiTypes';
import MetricService from 'services/MetricService';

const {
  publicRuntimeConfig: { CDN_STATIC_ASSETS, DFP_NETWORK_ID },
} = getConfig();

interface ServerSideProps extends HomePageResponse {
  type: 'error' | 'success';
  domain?: string;
  notificationData?: Notifications;
  unreadInAppMessage?: string;
  sectionToRequest?: SiteFocus;
  isLegacyLogoutQueryParam: boolean;
  isLegacyLogoutActionRequired: boolean;
  updatedUser: User | null;
  motorSearchFilters: ISearchPageFilter[];
}

function Home(props: ServerSideProps) {
  const { push } = useRouter();
  const { user, dispatchUser } = useUserContext();
  useEffect(() => {
    if (props.type === 'success' && props.dataTargetingProps) {
      pushToDataLayer(props.dataTargetingProps.gtm);
    }
    // TODO: Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    generateRecaptchaToken('home_page');
  }, []);

  const {
    domain = '',
    notificationData,
    unreadInAppMessage,
    sectionToRequest,
    isLegacyLogoutQueryParam,
    isLegacyLogoutActionRequired,
    updatedUser,
    motorSearchFilters,
  } = props;

  const [vertical, setVertical] = useState<SiteFocus>(
    sectionToRequest ? sectionToRequest : VERTICAL.MARKETPLACE,
  );
  /**
   * This flow is needed because:
   * 1. User signs in with keycloak
   * 2. User navigates to messages (legacy)
   * 3. User clicks signout
   * 4. Legacy navigates to /signout (new FE)
   * 5. keycloak and next-auth logout occur
   * 6. This final step clears legacy session
   */
  useEffect(() => {
    if (isLegacyLogoutActionRequired) {
      userLogout(dispatchUser, user?.name || '');
      push('/', undefined, { shallow: true });
    } else if (isLegacyLogoutQueryParam) {
      push('/', undefined, { shallow: true });
    }
    // TODO: Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLegacyLogoutQueryParam, isLegacyLogoutActionRequired]);

  useEffect(() => {
    if (updatedUser) {
      dispatchUser({ type: 'UPDATE', payload: updatedUser });
    }
    // TODO: Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedUser]);

  return (
    <HomePageProvider>
      <VerificationProvider>
        <NextSeo canonical={domain} />
        <Head>
          <meta
            name="google-site-verification"
            content="GHUykIchXG6tmbMfI3F6rSG5MVDBjzJN6iCyRilt8Kw"
          />
        </Head>

        <DefaultLayout
          domain={domain}
          headerVariant="TRANSPARENT"
          notificationData={notificationData}
          unreadInAppMessage={unreadInAppMessage}
          vertical={vertical}
        >
          <HomePage
            init={{
              siteFocus: props.siteFocus,
              homePageData: {
                h1: getH1(props.siteFocus),
                sections: props.sections,
                featuredAds: props.featuredDealer,
                recentAds: props.recentAds,
                recentAdsUrl: props.recentAdsUrl,
              },
            }}
            domain={domain}
            dfpNetworkId={DFP_NETWORK_ID}
            cdnUrl={CDN_STATIC_ASSETS}
            setVertical={setVertical}
            adCount={props.adCount}
            dashLinks={props.dashLinks}
            dataTargetingProps={props.dataTargetingProps}
            seoLinks={props.seoLinks}
            lifestyleLinks={props.lifestyleLinks}
            unreadInAppMessage={props.unreadInAppMessage}
            motorSearchFilters={motorSearchFilters}
          />
        </DefaultLayout>
      </VerificationProvider>
    </HomePageProvider>
  );
}

export const getServerSideProps: GetServerSideProps<ServerSideProps> = async (
  ctx,
) => {
  const { query } = ctx;
  const { headers } = ctx.req;
  const { cookie } = headers;

  let userCookie: UserCookieInfo | undefined = getServerCookie(
    'DDUS',
    ctx,
    true,
  );
  const isLegacyLogoutQueryParam = query?.signout === 'true';
  const isLegacyLogoutActionRequired =
    isLegacyLogoutQueryParam && Boolean(userCookie?.name);

  let motorSearchFilters: ISearchPageFilter[] = [];
  try {
    const { data } = await searchPageApi.getFilters('homepage-motors');
    motorSearchFilters = data.showByDefault || [];
  } catch (error) {
    console.error(logServerError(PAGE.HOME, error));
  }

  try {
    let notificationData;
    let unreadInAppMessage;

    let updatedUser: User | null = null;

    /**
     * The following code is to support this flow:
     * 1. A new user signs up with keycloak
     * 2. As they have just signed up, they are not verified
     * 3. They can't do certain actions until they verify
     * 4. They open email to verify, follow link and redirect to site
     * 5. Although keycloak knows they are verified, the legacy DDUS cookie is now outdated.
     *
     * So we get the up to date user information and update the user state
     *
     */
    const session = await getServerSession(ctx.req, ctx.res, authOptions);
    if (session?.user) {
      const userRes = await userRepository.get(
        formatAuthorizationHeader(session.accessToken),
      );
      if (isOk(userRes)) {
        const user = userRes.ok.user;
        updatedUser = {
          id: parseInt(user.id),
          name: user.name?.replace('+', ' ').split(' ')[0],
          type: Boolean(user.trader) ? 'TRADER' : 'PRIVATE', // doesn't matter here
          verification: {
            email: user.isEmailVerified,
            phone: user.isPhoneVerified,
            allowedAccess: false,
          },
          registrationdate: 'n/a', // doesn't matter here
          termsVersionAccepted: 'n/a', // doesn't matter here
          isMinimumVerified: user.isEmailVerified || user.isPhoneVerified,
        };
      }
    }

    const ddRecommendedUser = getServerCookie('ddRecommendedUser', ctx, true);
    const cookieSiteFocus =
      getServerCookie('ddSiteFocus', ctx, false) || 'motor';
    const sectionToRequest =
      cookieSiteFocus === 'everything' ? 'marketplace' : cookieSiteFocus;

    const getHomePageDataRequestHeaders = {
      ...(ddRecommendedUser && {
        dd_recommendation_user: ddRecommendedUser.toString(),
      }),
      ...(cookie && { cookie }),
    };

    const {
      data,
      headers: { unreadhistorychecks, unreadmessages, totalunread, unreadiam },
    }: AxiosResponse<HomePageResponse> = await adViewApi.getHomePageData(
      sectionToRequest,
      getHomePageDataRequestHeaders,
      query,
    );

    notificationData = {
      totalUnread: totalunread ?? null,
      unreadHistoryChecks: unreadhistorychecks ?? null,
      unreadMessages: unreadmessages ?? null,
    };

    unreadInAppMessage = unreadiam ?? null;

    return {
      props: {
        ...data,
        notificationData,
        unreadInAppMessage,
        type: 'success',
        sectionToRequest,
        isLegacyLogoutQueryParam,
        isLegacyLogoutActionRequired,
        updatedUser,
        motorSearchFilters,
      },
    };
  } catch (error) {
    console.error(logServerError(PAGE.HOME, error));

    if (axios.isAxiosError(error)) {
      const metricService = MetricService.get();
      metricService.incrementRequestErrorCount('HOME', {
        statusCode: error.response?.status,
      });
    }

    // if error we pass empty props so homepage is still rendered
    const data: HomePageResponse = {
      dataTargetingProps: {
        gtm: {},
        dfp: {},
      },
      recentAds: [],
      recommendedAds: [],
      siteFocus: 'motor',
      featuredDealer: null,
      recentAdsUrl: null,
      seoLinks: { internalLinks: [] },
      sections: [],
      dashLinks: [],
      lifestyleLinks: [],
      adCount: {
        carsTotalAdCount: 0,
      },
    };

    return {
      props: {
        ...data,
        type: 'error',
        isLegacyLogoutQueryParam,
        isLegacyLogoutActionRequired,
        updatedUser: null,
        motorSearchFilters,
      },
    };
  }
};

export default Home;
