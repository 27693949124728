import { ChevronForwardOutlineIc, ShieldCheckmark2Ic } from '@dsch/dd-icons';
import { useState, useEffect } from 'react';
import Link from 'next/link';

import { constructUrlParams, formatNumber } from 'helpers/formatting';
import { fireToast } from 'helpers/Toasts';
import { pushToDataLayer } from 'services/gtmService';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import { theme } from 'helpers/Theme';

import { autoCompleteApi } from 'api/autoCompleteApi';
import type { Suggestion } from 'api/types/autoCompleteApiTypes';
import type { AdCount } from 'api/types/adviewApiTypes';
import type { TLastSearch } from 'components/PageWrappers/SharedComponents/LastSearchCard/types';

import { MotorSearch } from 'components/HomePage/components/MotorSearch/MotorSearch';
import { VerticalSearch } from 'components/HomePage/components/VerticalSearch/VerticalSearch';
import {
  generateSearchDescription,
  formatSearchItemTerms,
  formatSearchTerms,
  formatSearchTermsModels,
  formatSearchTermsPrice,
  formatSearchTermsYears,
} from 'helpers/formatSearchTerms';
import { IHandleSelectSearchItem } from 'components/Toolkit/SearchModal/BaseSearch';
import { VERTICAL } from 'helpers/verticals';
import { useLocationContext } from 'features/location/Location.context';
import { DEFAULT_PRESELECT } from 'features/location/Location.constants';
import { handlePushToDataLayerForLocationSearch } from 'features/location/helpers/Tracking';
import { restoreScrollPosition } from 'helpers/scroll';
import { ISearchPageFilter } from 'api/types/searchPageApiTypes';
import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';

import {
  Wrapper,
  Container,
  HomePageSearchButton,
  DoneDealMotorEntryPoint,
  DoneDealMotorLink,
  CertifiedTrustedDealerEntryPointCopy,
} from 'components/HomePage/Layouts/HomePageSearchManager.styled';

export type HomePageSearchManagerProps = {
  cdnUrl: string;
  domain: string;
  focus: string;
  setLastSearch: (searchTerms: string, url: string) => void;
  lastSearchData?: TLastSearch;
  lastSearchDataTrackingValue?: string;
  className?: string;
  adCount: AdCount;
  motorSearchFilters: ISearchPageFilter[];
};

const HomePageSearchManager = (props: HomePageSearchManagerProps) => {
  const {
    cdnUrl,
    domain,
    focus,
    className,
    lastSearchData,
    setLastSearch,
    lastSearchDataTrackingValue,
    adCount = {
      carsTotalAdCount: 0,
    },
    motorSearchFilters,
  } = props;

  const PLACEHOLDER = 'placeholder';

  const [marketPlaceQuery, setMarketPlaceQuery] = useState<string>('');
  const [farmingQuery, setFarmingQuery] = useState<string>('');
  const [make, setMake] = useState<string>('');
  const [models, setModels] = useState<string[]>(['All Models']);
  const [priceFrom, setPriceFrom] = useState<string>(PLACEHOLDER);
  const [priceTo, setPriceTo] = useState<string>(PLACEHOLDER);
  const [yearFrom, setYearFrom] = useState<string>(PLACEHOLDER);
  const [yearTo, setYearTo] = useState<string>(PLACEHOLDER);
  const [autoSuggestValues, setAutoSuggestValues] = useState<Suggestion[]>();
  const [filteredSectionCount, setFilteredSectionCount] = useState<number>();
  const [searchCTAText, setSearchCTAText] = useState<string>(
    focus === VERTICAL.MOTOR
      ? `Search ${
          adCount.carsTotalAdCount === 0
            ? ''
            : `${formatNumber(adCount.carsTotalAdCount)} `
        }Cars`
      : `Search ${focus}`,
  );

  useEffect(() => {
    setMake('');
  }, [focus]);

  const { selectedCounty, countyTown, coordinates, radius, resetToDefault } =
    useLocationContext();

  useOnUpdateOnly(() => {
    resetToDefault();
  }, [focus]);

  const handleVerticalSearch = (
    verticalQuery: string,
    section: string,
    sectionDisplayName: string,
  ) => {
    let verticalParams;
    let locationQuery;

    if (
      selectedCounty !== null &&
      selectedCounty?.value !== DEFAULT_PRESELECT.value
    ) {
      const { latitude, longitude } = coordinates;
      verticalParams = constructUrlParams({
        words: verticalQuery,
        area: selectedCounty.value,
        countyTown:
          Boolean(latitude && longitude) && countyTown ? countyTown : undefined,
        radius: Boolean(latitude && longitude) ? radius : undefined,
      });
      locationQuery = `${selectedCounty.displayName}${
        countyTown ? `, ${countyTown} (+${radius}km)` : ''
      }`;
    } else {
      verticalParams = constructUrlParams({
        words: verticalQuery,
      });
    }

    const verticalUrl = `${domain}/${section}${
      verticalParams ? `?${verticalParams}` : ''
    }`;

    if (verticalQuery || locationQuery) {
      const formattedVerticalQuery = verticalQuery
        ? ` · '${marketPlaceQuery}'`
        : '';
      const formattedLocationQuery = locationQuery ? ` · ${locationQuery}` : '';
      setLastSearch(
        `${sectionDisplayName}${formattedVerticalQuery}${formattedLocationQuery}`,
        verticalUrl,
      );
    }
    if (selectedCounty?.displayName) {
      handlePushToDataLayerForLocationSearch({
        county: selectedCounty?.displayName,
        countyTown,
        radius,
      });
    }

    restoreScrollPosition(`${verticalUrl.replace(domain, '')}`);

    location.assign(verticalUrl);
  };

  function handleSearch() {
    switch (focus) {
      case VERTICAL.MOTOR:
        const price_from = priceFrom === 'placeholder' ? undefined : priceFrom;
        const price_to = priceTo === 'placeholder' ? undefined : priceTo;
        const year_from = yearFrom === 'placeholder' ? undefined : yearFrom;
        const year_to = yearTo === 'placeholder' ? undefined : yearTo;
        const motorParams = constructUrlParams({
          price_from,
          price_to,
          year_from,
          year_to,
          make,
        });
        const motorUrl = `${domain}/cars${motorParams && `?${motorParams}`}${
          models !== undefined &&
          models.length !== 0 &&
          models[0] !== '' &&
          models[0] !== 'All Models'
            ? `;model:${encodeURIComponent(models.toString())}`
            : ''
        }`;
        pushToDataLayer({
          event: 'homepage_filter',
          hasMakeModel: Boolean(make),
          hasPrice: Boolean(
            priceFrom !== PLACEHOLDER || priceTo !== PLACEHOLDER,
          ),
          hasYear: Boolean(yearFrom !== PLACEHOLDER || yearTo !== PLACEHOLDER),
          url: motorUrl,
        });

        const year = formatSearchTermsYears(year_from, year_to);
        const price = formatSearchTermsPrice(price_from, price_to, '€');
        const makeAndModel = models
          ? `${make} ${formatSearchTermsModels(models)}`
          : make;
        const lastSearchTerms = ['Cars', makeAndModel, year, price].filter(
          Boolean,
        );
        setLastSearch(formatSearchTerms(lastSearchTerms as string[]), motorUrl);

        restoreScrollPosition(`${motorUrl.replace(domain, '')}`);

        location.assign(motorUrl);
        break;
      case VERTICAL.MARKETPLACE:
        handleVerticalSearch(marketPlaceQuery, 'all', 'All Sections');
        break;
      case VERTICAL.FARMING:
        handleVerticalSearch(farmingQuery, 'farming', 'Farming');
        break;
    }
  }

  function handleSelectSearchItem({
    autoSuggestedText,
    searchRequest,
    url,
    category,
  }: IHandleSelectSearchItem) {
    let urlParams;
    const description = generateSearchDescription(
      searchRequest?.makeModelFilters,
      autoSuggestedText,
    );
    if (
      selectedCounty !== null &&
      selectedCounty?.value !== DEFAULT_PRESELECT.value
    ) {
      const { latitude, longitude } = coordinates;
      urlParams = `${url?.includes('?') ? '&' : '?'}${constructUrlParams({
        area: selectedCounty.value,
        countyTown: countyTown ?? undefined,
        radius: Boolean(latitude && longitude) ? radius : undefined,
      })}`;
    }
    let formattedUrl: string;
    if (url) {
      if (focus === VERTICAL.MOTOR) {
        formattedUrl = `${url.replace('all', VERTICAL.FARMING)}${
          urlParams ?? ''
        }`;
      } else {
        formattedUrl = `${url}${urlParams ?? ''}`;
      }
    } else {
      formattedUrl = `${domain}/${
        category ?? (focus === VERTICAL.FARMING ? VERTICAL.FARMING : 'all')
      }?words=${autoSuggestedText}${urlParams ?? ''}`;
    }

    restoreScrollPosition(`${formattedUrl.replace(domain, '')}`);

    location.assign(formattedUrl);
    setLastSearch(
      formatSearchItemTerms(description, category, autoSuggestedText),
      formattedUrl,
    );
  }

  async function handleSearchInput(text: string) {
    if (focus === VERTICAL.FARMING) {
      setFarmingQuery(text);
    } else {
      setMarketPlaceQuery(text);
    }
    if (text.length > 2) {
      try {
        const { data } = await autoCompleteApi.getAutoCompleteValues(text);
        setAutoSuggestValues(data.suggestions);
      } catch (error) {
        rg4js('send', {
          error: new Error('Error handling handleSearchInput'),
          tags: [PAGE.HOME],
          customData: {
            message: error.message || 'client_error',
          },
        });
        fireToast({
          type: 'ERROR',
          text: 'Oops! Something went wrong, please try again later',
        });
      }
    } else {
      setAutoSuggestValues(undefined);
    }
  }

  useOnUpdateOnly(() => {
    if (focus === VERTICAL.MOTOR) {
      if (filteredSectionCount === 0) {
        setSearchCTAText('Search Cars');
      } else if (filteredSectionCount === undefined) {
        setSearchCTAText(
          `Search ${
            adCount.carsTotalAdCount === 0
              ? ''
              : `${formatNumber(adCount.carsTotalAdCount)} `
          }Cars`,
        );
      } else {
        setSearchCTAText(`Search ${formatNumber(filteredSectionCount)} Cars`);
      }
    } else {
      setYearFrom(PLACEHOLDER);
      setYearTo(PLACEHOLDER);
      setPriceFrom(PLACEHOLDER);
      setPriceTo(PLACEHOLDER);
      setFilteredSectionCount(undefined);
      setSearchCTAText(`Search ${focus}`);
    }
  }, [filteredSectionCount, focus]);

  const DisplayVertical = (focus: string) => {
    switch (focus) {
      case VERTICAL.MOTOR:
        return (
          <MotorSearch
            make={make}
            priceFrom={priceFrom}
            priceTo={priceTo}
            yearFrom={yearFrom}
            yearTo={yearTo}
            onMakeSelect={setMake}
            onModelsSelect={setModels}
            onYearFromSelect={setYearFrom}
            onYearToSelect={setYearTo}
            onPriceFromSelect={setPriceFrom}
            onPriceToSelect={setPriceTo}
            setFilteredSectionCount={setFilteredSectionCount}
            searchFilters={motorSearchFilters}
          />
        );
      case VERTICAL.MARKETPLACE:
      case VERTICAL.FARMING:
        return (
          <VerticalSearch
            baseUrl={domain}
            onInput={handleSearchInput}
            onSearch={handleSearch}
            onHandleSelectSearchItem={handleSelectSearchItem}
            query={marketPlaceQuery}
            autoSuggestValues={autoSuggestValues}
            lastSearchData={lastSearchData}
            lastSearchDataTrackingValue={lastSearchDataTrackingValue}
            searchPlaceholder={
              focus === VERTICAL.MARKETPLACE
                ? 'Search DoneDeal'
                : 'Search Farming'
            }
          />
        );
      default:
        return null;
    }
  };

  const CERTIFIED_TRUSTED_DEALER_URL = '/cars?verifications=trustedDealer';

  return (
    <Wrapper className={className}>
      <Container>
        {DisplayVertical(focus)}

        <HomePageSearchButton
          section={focus}
          size="LARGE"
          onClick={handleSearch}
          data-testid="homepage-search-button"
        >
          {searchCTAText}
        </HomePageSearchButton>

        <DoneDealMotorEntryPoint section={focus}>
          {focus === VERTICAL.MOTOR && (
            <DoneDealMotorLink
              href={CERTIFIED_TRUSTED_DEALER_URL}
              ofType="SECONDARY"
              colorVariant="WHITE"
              NextLink={Link}
              fontSize="M16"
              data-testid="certified-trusted-dealer-hero-entry-point"
              onClick={() => {
                restoreScrollPosition(CERTIFIED_TRUSTED_DEALER_URL);
                sessionStorage.setItem(
                  `scrollPos:${CERTIFIED_TRUSTED_DEALER_URL}`,
                  JSON.stringify({ x: 0, y: 0 }),
                );
              }}
              data-tracking="certified-trusted-dealer-cars-homepage-entry-point"
            >
              <ShieldCheckmark2Ic
                width={16}
                height={16}
                color={theme.colors.GREEN}
              />
              <CertifiedTrustedDealerEntryPointCopy>
                View Certified Trusted Dealer Cars
              </CertifiedTrustedDealerEntryPointCopy>
              <ChevronForwardOutlineIc
                color={theme.colors.WHITE}
                height={16}
                width={16}
              />
            </DoneDealMotorLink>
          )}

          {focus !== VERTICAL.MOTOR && (
            <DoneDealMotorLink
              href="cars"
              ofType="SECONDARY"
              colorVariant="WHITE"
              NextLink={Link}
              onClick={() => {
                restoreScrollPosition(`/cars`);
              }}
              data-testid={`hp-${focus}-find-cars`}
            >
              <span>Find your next car on</span>&nbsp;
              <img
                src={`${cdnUrl}/images/logos/donedeal-motor-full.svg`}
                alt="DoneDeal Motors"
              />
            </DoneDealMotorLink>
          )}
        </DoneDealMotorEntryPoint>
      </Container>
    </Wrapper>
  );
};

export { HomePageSearchManager };
