import { ChevronBackOutlineIc, ChevronForwardOutlineIc } from '@dsch/dd-icons';

import { useCarousel } from 'features/carousel/Carousel.hook';

import * as Styled from 'features/carousel/Carousel.styled';

import type { CarouselProps } from 'features/carousel/Carousel.typed';

const Carousel = (props: CarouselProps) => {
  const {
    children,
    scrollDistance,
    title,
    className,
    accessibilityLabel,
    'data-testid': dataTestId,
    id,
  } = props;

  const {
    scrollLeft,
    scrollRight,
    onScroll,
    carouselRef,
    isAtStartPosition,
    isAtEndPosition,
  } = useCarousel({
    scrollDistance,
  });

  return (
    <Styled.Container className={className} data-testid={dataTestId}>
      <Styled.Heading>
        <Styled.Title>{title}</Styled.Title>
        <Styled.NavigationButtonContainer>
          <Styled.NavigationButton
            size="SMALL"
            disabled={isAtStartPosition}
            onClick={scrollLeft}
            aria-label={`Show previous ${accessibilityLabel}`}
          >
            <ChevronBackOutlineIc width="16" height="16" />
          </Styled.NavigationButton>
          <Styled.NavigationButton
            size="SMALL"
            disabled={isAtEndPosition}
            onClick={scrollRight}
            aria-label={`Show next ${accessibilityLabel}`}
          >
            <ChevronForwardOutlineIc width="16" height="16" />
          </Styled.NavigationButton>
        </Styled.NavigationButtonContainer>
      </Styled.Heading>
      <Styled.Carousel ref={carouselRef} onScroll={onScroll} id={id}>
        {children}
      </Styled.Carousel>
    </Styled.Container>
  );
};

export { Carousel };
