import type { TAutoCompleteSearchRequestMakeModelFilters } from 'types';

export function formatSearchTerms(terms: string[], max: number = 4): string {
  const newTerms = terms.slice(0, max);
  const plusOrNot =
    terms.length > newTerms.length
      ? ` (+${terms.length - newTerms.length})`
      : '';
  return `${newTerms.join(' · ')}${plusOrNot}`;
}

export const truncateSearchTerms = (terms: Array<string>, limit: number) => {
  const truncatedTerms = terms.slice(0, limit);
  if (terms.length > truncatedTerms.length) {
    return [
      ...truncatedTerms,
      `+ ${terms.length - truncatedTerms.length} more`,
    ];
  } else return terms;
};

export function formatSearchItemTerms(
  description?: string,
  category?: string,
  autoSuggestedText?: string,
) {
  return `${
    category
      ? // Presence or absence of autoSuggestedText (leftText in search suggestion) determines format of category
        autoSuggestedText
        ? category.slice(3) // In <SectionName>
        : category.split(' ')[0] // <SectionName> Section
      : 'All Sections'
  }${description ? ` · ${description}` : ''}`;
}

export function formatSearchTermsPrice(
  priceFrom?: string,
  priceTo?: string,
  currency: string = '€',
) {
  if (!priceFrom && !priceTo) {
    return '';
  }
  if (priceFrom === priceTo) {
    return `${currency}${priceFrom}`;
  }
  if (!priceFrom || !priceTo) {
    return `${priceFrom ? '>' : '<'} ${currency}${priceFrom || priceTo}`;
  }
  return `${currency}${priceFrom} - ${currency}${priceTo}`;
}

export function formatSearchTermsYears(yearFrom?: string, yearTo?: string) {
  if (!yearFrom && !yearTo) {
    return '';
  }
  if (yearFrom === yearTo) {
    return yearFrom;
  }
  if (!yearTo || !yearFrom) {
    return `${yearFrom ? '>' : '<'} ${yearFrom || yearTo}`;
  }
  return `${yearFrom} - ${yearTo}`;
}

export function formatSearchTermsModels(models: string[]) {
  if (models.length === 0) {
    return '(All Models)';
  }
  if (models.length === 1) {
    return models[0];
  }
  return `${models[0]} (+${models.length - 1})`;
}

export function generateMakeModelDescription(
  makeModelFilters?: TAutoCompleteSearchRequestMakeModelFilters[],
) {
  return makeModelFilters && makeModelFilters.length > 0
    ? `${makeModelFilters[0].make}${
        makeModelFilters[0].model
          ? ` ${makeModelFilters[0].model}`
          : ' (All Models)'
      }`
    : undefined;
}

export function generateSearchDescription(
  makeModelFilters?: TAutoCompleteSearchRequestMakeModelFilters[],
  userInput?: string,
) {
  const makeModelDescription = generateMakeModelDescription(makeModelFilters);
  if (makeModelDescription) {
    return makeModelDescription;
  }
  return userInput ? `'${userInput}'` : undefined;
}

export const serialise = (obj: {
  [key: string]: string | Array<string> | undefined;
}) => {
  const params = new URLSearchParams();

  Object.keys(obj).map((key) => {
    const value = obj[key];

    if (Array.isArray(value)) {
      return value.forEach((item) => params.append(key, item));
    } else if (typeof value === 'string') {
      return params.append(key, value);
    }
  });

  return `?${params.toString()}`;
};
