import styled from 'styled-components';
import { media } from 'helpers/breakpoints';

import { Button } from 'components/Toolkit/Button/Button';
import { Link } from 'components/Toolkit/Button/Link';

import { VERTICAL } from 'helpers/verticals';

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.div`
  position: relative;
  height: 240px;

  ${media.medium} {
    height: 176px;
  }
`;

type HomePageSearchButtonStyledProps = {
  section: string;
};
const HomePageSearchButton = styled(Button)<HomePageSearchButtonStyledProps>`
  position: absolute;
  bottom: 0;
  text-transform: capitalize;
  transition: all 0.2s;
  transform: translateY(
    ${({ section }) => (section !== VERTICAL.MOTOR ? '-64px' : '0')}
  );

  ${media.medium} {
    position: static;
    transform: translateY(0);
  }
`;

type DoneDealMotorEntryPointStyledProps = { section: string };
const DoneDealMotorEntryPoint = styled.div<DoneDealMotorEntryPointStyledProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  ${({ theme }) => theme.fontSize.M14};
  color: ${({ theme }) => theme.colors.WHITE};
  bottom: ${({ section }) => (section === VERTICAL.MOTOR ? '-40px' : '16px')};

  ${media.medium} {
    bottom: -40px;
  }
`;

const DoneDealMotorLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const CertifiedTrustedDealerEntryPointCopy = styled.span`
  margin: ${({ theme }) => `0 ${theme.spacing.S2} 0 ${theme.spacing.S4}`};

  ${media.medium} {
    margin: ${({ theme }) => `0 ${theme.spacing.S4}`};
  }
`;

export {
  Wrapper,
  Container,
  HomePageSearchButton,
  DoneDealMotorEntryPoint,
  DoneDealMotorLink,
  CertifiedTrustedDealerEntryPointCopy,
};
